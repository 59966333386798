.datepickerDiv > div, .datepickerDiv > div > div {
    display: block;
}

.card_body {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.form_column {
    flex-basis: 22rem;
    padding: 0 3px;
}
