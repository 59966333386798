.card{
    display:flex;
    flex-direction: column;
    height:100%;
    /*width:36vw;*/
    /*max-width: 22rem;*/
    /*height:27vw;*/
    /*max-height: 17rem;*/

    padding-bottom: 1rem;
}
.card-menu{
    height:50%;
}
.listArea{
    display:flex;
    flex-direction: column;
    padding:1rem 0.2rem 2rem;
    background-color: #f1f1fc;
    border-radius: 3px;
    flex: 1 1 40%;

    overflow-y: auto;
}
.item{
    /*display:flex;*/
    /*flex-direction: column;*/
    /*padding:2px;*/
    /*background-color: cyan;*/
    /*border-radius: 3px;*/
    /*flex: 1 1 200px;*/
    /*width:36vw;*/
    /*max-width: 22rem;*/
    /*height:27vw;*/
    /*max-height: 17rem;*/

    /*margin-bottom: 0.5rem;*/
}
/* width */
.listArea::-webkit-scrollbar {
    width: 3px;
}

/* Track */
.listArea::-webkit-scrollbar-track {
    background: #f1f1f1;
    box-shadow: inset 0 0 5px lightblue;
    border-radius: 10px;
}
/* Handle */
.listArea::-webkit-scrollbar-thumb {
    background: #888;
}
/* Handle on hover */
.listArea::-webkit-scrollbar-thumb:hover {
    background: #555;
}