.dashboard-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;
    height: 100%;
}

.plansSection {
    display: flex;
    text-align: center;
    width: 80%;
    max-width: 20rem;
    flex-grow: 1;

    height: 80vh;
}

.exercisesSection {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: baseline;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 1rem;
    /*{!*height: "80vh"*!}*/
    /*height: 100%;*/
}

.cardContainer {
    display: flex;
    text-align: center;
    /*justify-content: space-between;*/
    /*max-width: 30rem;*/
    /*min-width: 16rem;*/
    flex: 1 1 40%;
    /*flex-grow: 1;*/
    /*    height: "80vh",*/
    height: 80vh;
    /*margin: auto 0.5rem;*/
}
.card {
    flex:1;
    max-width: 32rem;
}
/*className='d-flex flex-column flex-grow-1' style={{height: "75vh"}}*/
.cardContext {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    height: 100%;
    /*margin: auto 0.5rem;*/
}

.avatarSpeech img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 768px) {
    .dashboard-container {
        gap: 2rem;
    }

    .avatarSpeech {
        height: 22.5vh;
        /*width:30vh;*/
    }
}

@media only screen and (max-width: 640px) {
    .avatar {
        max-width: 14rem; /* 15 */
        min-width: 10rem;
        max-height: 10rem; /* 12 */
    }

    .avatarSpeech {
        height: 21vh;
        /*width:28vh;*/
    }
}