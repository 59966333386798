.card {
    /*flex: 50;*/
    background: white;
    border-radius: 6px;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}