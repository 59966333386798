.tab{
    margin:0 3px;
    padding:6px;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-radius: 6px;
    cursor: pointer;
}
.tab:hover{
    background: #C9F7F5;
}
.activeTab{
    background: #E5F9F7;
}
.activeTitle {
    font-weight: bolder;
}